import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
  const services = [
    {
      id: 1,
      title: "Technology Strategy & Architecture",
      description: "Build a strong foundation for your technology initiatives with our comprehensive strategy and architecture services.",
      icon: "architecture-icon.svg",
      features: [
        "IT infrastructure assessment and planning",
        "Cloud and on-premise architecture design",
        "Technology governance framework development",
        "Scalable system design",
        "Technology stack optimization"
      ]
    },
    {
      id: 2,
      title: "Digital Transformation Solutions",
      description: "Transform your business operations with our custom digital solutions.",
      icon: "bi-icon.svg",
      features: [
        "Interactive application development",
        "Automated workflow systems",
        "Performance tracking and monitoring",
        "User experience optimization",
        "Self-service digital platforms"
      ]
    },
    {
      id: 3,
      title: "Process Optimization",
      description: "Streamline your operations and reduce costs through technology-driven process improvements.",
      icon: "operations-icon.svg",
      features: [
        "Process analysis and mapping",
        "Efficiency optimization",
        "Automation implementation",
        "Performance monitoring",
        "Continuous improvement frameworks"
      ]
    },
    {
      id: 4,
      title: "Advanced Technology Solutions",
      description: "Leverage the power of cutting-edge technologies to drive better business decisions.",
      icon: "analytics-icon.svg",
      features: [
        "Innovative solution development",
        "Emerging technology integration",
        "Digital transformation consulting",
        "Technology trend analysis",
        "Strategic technology implementation"
      ]
    }
  ];

  return (
    <div className="page-container">
      {/* Services Section */}
      <div className="max-w-6xl mx-auto py-16 px-4 bg-transparent">
        <div className="text-center mb-12">
          <h1 className="heading-1">Our Services</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Comprehensive technology solutions designed to help your business thrive in 
            the digital age. From strategy to implementation, we're here to help.
          </p>
        </div>
        <div className="grid gap-16">
          {services.map((service, index) => (
            <div key={service.id} className={`md:flex items-center gap-12 ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}>
              <div className="md:w-1/2 mb-8 md:mb-0">
                <img
                  src={`/images/icons/${service.icon}`}
                  alt={service.title}
                  className="w-32 h-32 mx-auto md:mx-0"
                />
              </div>
              <div className="md:w-1/2">
                <h2 className="heading-2 mb-4">{service.title}</h2>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <svg className="w-5 h-5 text-[var(--accent-color)] mr-3" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div>
        <div className="max-w-6xl mx-auto py-16 px-4 text-center">
          <h2 className="heading-2 mb-4">Ready to Get Started?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Let's discuss how our services can help transform your business with 
            technology solutions.
          </p>
          <Link to="/contact" className="btn-primary">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;