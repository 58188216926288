import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const services = [
    {
      id: 1,
      title: "Technology Strategy & Architecture",
      description: "Design and implement robust technology architectures that scale with your business needs. From cloud infrastructure to systems integration, we build foundations for success.",
      icon: "architecture-icon.svg"
    },
    {
      id: 2,
      title: "Digital Transformation Solutions",
      description: "Transform your business with custom digital tools, automated workflows, and interactive user experiences.",
      icon: "bi-icon.svg"
    },
    {
      id: 3,
      title: "Process Optimization",
      description: "Streamline operations and reduce costs through technology-driven process improvements and automation solutions.",
      icon: "operations-icon.svg"
    },
    {
      id: 4,
      title: "Advanced Technology Solutions",
      description: "Leverage cutting-edge technologies to gain competitive advantages, improve efficiency, and make better business decisions.",
      icon: "analytics-icon.svg"
    }
  ];

  return (
    <div className="page-container">
      {/* Hero Section */}
      <div className="bg-[var(--neutral-light)]">
        <div className="max-w-6xl mx-auto py-16 px-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-[var(--primary-color)]">
              Transform Your Business with Technology Solutions
            </h1>
            <p className="text-xl mb-8 max-w-3xl mx-auto text-[var(--neutral-dark)]">
              We help organizations leverage the power of technology to drive efficiency, 
              innovation, and growth through custom digital solutions.
            </p>
            <Link to="/contact" className="btn-primary">
              Get Started
            </Link>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="max-w-6xl mx-auto py-16 px-4">
        <div className="text-center mb-12">
          <h2 className="heading-2">Our Services</h2>
          <p className="text-xl text-gray-600">
            Comprehensive data solutions tailored to your business needs
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {services.map(service => (
            <div key={service.id} className="card hover:shadow-lg transition-shadow">
              <div className="p-6">
                <img
                  src={`/images/icons/${service.icon}`}
                  alt={service.title}
                  className="w-16 h-16 mb-4"
                />
                <h3 className="heading-3 mb-3">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="bg-[var(--neutral-light)]">
        <div className="max-w-6xl mx-auto py-16 px-4">
          <div className="text-center mb-12">
            <h2 className="heading-2">Why Choose Us</h2>
            <p className="text-xl text-gray-600">
              Our approach to delivering value through data
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-[var(--primary-color)]" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
              </div>
              <h3 className="heading-3 mb-2">Expert Team</h3>
              <p className="text-gray-600">
                Experienced professionals with deep industry knowledge
              </p>
            </div>

            <div className="text-center">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-[var(--primary-color)]" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
                </svg>
              </div>
              <h3 className="heading-3 mb-2">Best Practices</h3>
              <p className="text-gray-600">
                Industry-standard methodologies and proven approaches
              </p>
            </div>

            <div className="text-center">
              <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-[var(--primary-color)]" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
              </div>
              <h3 className="heading-3 mb-2">Results-Driven</h3>
              <p className="text-gray-600">
                Focus on delivering measurable business outcomes
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="max-w-6xl mx-auto py-16 px-4 text-center">
        <h2 className="heading-2 mb-4">Ready to Get Started?</h2>
        <p className="text-xl text-gray-600 mb-8">
          Let's discuss how we can help transform your business with technology solutions.
        </p>
        <Link to="/contact" className="btn-primary">
          Contact Us
        </Link>
      </div>
    </div>
  );
}

export default Home;
