import React from 'react';

function Terms() {
  return (
    <div className="page-container">
      <div className="max-w-4xl mx-auto">
        <div className="content-section prose">
          <h1 className="heading-1">Terms of Service</h1>
          
          <div className="mb-8">
            <p className="lead">
              These Terms of Service ("Terms") govern your access to and use of 
              the services provided by redMug Solutions LLC ("we", "us", or "our").
            </p>
          </div>

          <h2>Acceptance of Terms</h2>
          <p>
            By accessing or using our services, you agree to be bound by these Terms. 
            If you do not agree to these Terms, you may not access or use our services.
          </p>

          <h2>Description of Services</h2>
          <p>
            We provide technology solutions for businesses, including but not limited to:
          </p>
          <ul>
            <li>Technology Strategy & Architecture</li>
            <li>Digital Transformation Solutions</li>
            <li>Process Optimization</li>
            <li>Advanced Technology Solutions</li>
          </ul>
          
          <h2>Your Responsibilities</h2>
          <p>
            You are responsible for:
          </p>
          <ul>
            <li>Providing accurate and complete information</li>
            <li>Maintaining the confidentiality of your account credentials</li>
            <li>Complying with all applicable laws and regulations</li>
            <li>Using our services in a manner that does not infringe on the rights of others</li>
          </ul>

          <h2>Intellectual Property</h2>
          <p>
            Our services, including all content, features, and functionality, are owned by us 
            or our licensors and are protected by copyright, trademark, and other intellectual 
            property laws.
          </p>
          <p>
            You may not:
          </p>
          <ul>
            <li>Copy, modify, or create derivative works based on our services</li>
            <li>Distribute, publicly display, or publicly perform our services</li>
            <li>Remove any copyright, trademark, or other proprietary notices</li>
          </ul>

          <h2>Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, we shall not be liable for any indirect, 
            incidental, special, consequential, or punitive damages arising out of or relating 
            to your access to or use of our services.
          </p>

          <h2>Termination</h2>
          <p>
            We may terminate or suspend your access to our services at any time, without prior 
            notice or liability, for any reason whatsoever, including without limitation if you 
            breach these Terms.
          </p>

          <h2>Changes to These Terms</h2>
          <p>
            We reserve the right to modify or replace these Terms at any time. If we make material 
            changes, we will notify you through our website or by other means.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <p>
            Email: legal@redmug.co
          </p>
          
          <div className="info-box mt-8">
            <h3>Last Updated</h3>
            <p>These terms were last updated on March 9, 2023.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms; 