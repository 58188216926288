import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[var(--neutral-light)] border-t border-[var(--gray-300)] py-10 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-4">
              <span className="text-[var(--accent-color)]">red</span>Mug Solutions
            </h3>
            <p className="text-sm text-[var(--neutral-dark)]">
              Empowering organizations with innovative technology solutions.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Home</Link></li>
              <li><Link to="/services" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Services</Link></li>
              <li><Link to="/about-us" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">About Us</Link></li>
              <li><Link to="/blog" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Blog</Link></li>
            </ul>
          </div>

          {/* Contact */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-sm text-[var(--neutral-dark)]">info@redmug.co</li>
              <li><Link to="/contact" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Contact Form</Link></li>
            </ul>
          </div>

          {/* Legal */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms" className="text-sm text-[var(--neutral-dark)] hover:text-[var(--accent-color)] transition-colors">Terms of Service</Link></li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-10 pt-6 border-t border-[var(--gray-300)] text-center">
          <p className="text-sm text-[var(--neutral-dark)]">
            © {currentYear} <span className="text-[var(--accent-color)]">red</span>Mug Solutions LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 