import React from 'react';

function Privacy() {
  return (
    <div className="page-container">
      <div className="max-w-4xl mx-auto">
        <div className="content-section prose">
          <h1 className="heading-1">Privacy Policy</h1>
          
          <div className="mb-8">
            <p className="lead">
              This Privacy Policy describes how redMug Solutions LLC ("we", "us", or "our") 
              collects, uses, and shares your personal information when you use our website 
              or services.
            </p>
          </div>

          <h2>Information We Collect</h2>
          <p>
            We may collect personal information that you provide directly to us, such as:
          </p>
          <ul>
            <li>Contact information (name, email address, phone number)</li>
            <li>Business information (company name, job title)</li>
            <li>Account credentials (if you create an account)</li>
            <li>Feedback, inquiries, and other communications</li>
          </ul>

          <h2>How We Use Your Information</h2>
          <p>
            We use the information we collect to:
          </p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Respond to your requests and inquiries</li>
            <li>Send you technical notices, updates, and administrative messages</li>
            <li>Communicate with you about products, services, and events</li>
            <li>Monitor and analyze trends, usage, and activities</li>
          </ul>

          <h2>How We Share Your Information</h2>
          <p>
            We may share your personal information with:
          </p>
          <ul>
            <li>Service providers who perform services on our behalf</li>
            <li>Professional advisors, such as lawyers and accountants</li>
            <li>Regulatory authorities, government agencies, and law enforcement</li>
          </ul>
          
          <h2>Your Choices</h2>
          <p>
            You have certain choices about how we use your personal information:
          </p>
          <ul>
            <li>You can opt out of receiving promotional communications</li>
            <li>You can update, correct, or delete your account information</li>
            <li>You can choose whether to provide certain information</li>
          </ul>

          <h2>Security</h2>
          <p>
            We take reasonable measures to help protect your personal information 
            from loss, theft, misuse, unauthorized access, disclosure, alteration, 
            and destruction.
          </p>

          <h2>Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. If we make material 
            changes, we will notify you through our website or by other means.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <p>
            Email: privacy@redmug.co
          </p>
          
          <div className="info-box mt-8">
            <h3>Last Updated</h3>
            <p>This policy was last updated on March 9, 2023.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy; 