import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import Login from './components/Login';
import { AuthProvider } from './contexts/AuthContext';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="min-h-screen bg-[var(--neutral-light)] flex flex-col">
          {/* Mobile menu overlay */}
          <div 
            className={`fixed inset-0 bg-black/50 transition-opacity duration-300 z-40
              ${isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
            onClick={() => setIsMobileMenuOpen(false)}
          />
          
          {/* Header */}
          <header className="fixed top-0 left-0 right-0 bg-[var(--primary-color)] shadow-md z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
              <div className="flex justify-between items-center">
                {/* Logo and Brand */}
                <div className="flex items-center space-x-3">
                  <img 
                    src={logo} 
                    className="h-10 w-10 transition-transform hover:rotate-180 duration-1000" 
                    alt="redMug Solutions LLC" 
                  />
                  <h1 className="text-white text-xl font-bold">
                    <span className="text-[var(--accent-color)]">red</span>Mug Solutions LLC
                  </h1>
                </div>
                
                {/* Mobile menu button */}
                <button 
                  className="md:hidden flex flex-col space-y-1.5 p-2 text-white"
                  onClick={toggleMobileMenu}
                  aria-label="Toggle menu"
                >
                  <span className="w-6 h-0.5 bg-white block"></span>
                  <span className="w-6 h-0.5 bg-white block"></span>
                  <span className="w-6 h-0.5 bg-white block"></span>
                </button>

                {/* Navigation */}
                <nav className={`
                  fixed md:relative top-0 right-0 h-full md:h-auto
                  w-64 md:w-auto bg-[var(--primary-color)] md:bg-transparent
                  transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}
                  md:transform-none transition-transform duration-300 ease-in-out
                  z-50 md:z-auto
                `}>
                  <ul className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-6 p-6 md:p-0">
                    {[
                      { to: "/", text: "Home" },
                      { to: "/services", text: "Services" },
                      { to: "/about-us", text: "About Us" },
                      { to: "/contact", text: "Contact" },
                      { to: "/login", text: "Client Login" },
                      { to: "/blog", text: "Blog" },
                    ].map((link) => (
                      <li key={link.to}>
                        <Link
                          to={link.to}
                          className="text-white hover:text-[var(--accent-color)] transition-colors duration-200"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          {link.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          {/* Main Content */}
          <main className="flex-grow pt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route 
                path="/dashboard" 
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                } 
              />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </main>
          
          {/* Footer */}
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;