import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  // Sample blog data - in a real app, this would come from an API
  const blogPosts = [
    {
      id: 1,
      title: "Understanding Business Intelligence",
      excerpt: "An exploration of how modern businesses are leveraging technology for better decision-making.",
      image: "/images/blog/bi-dashboard.svg",
      date: "March 12, 2023",
      author: "redMug.co",
      category: "Digital Solutions",
      readTime: "5 min read"
    },
    {
      id: 2,
      title: "Security Best Practices for Small Businesses",
      excerpt: "Essential security measures every small business should implement to protect their technology infrastructure and maintain customer trust.",
      image: "/images/blog/security.svg",
      category: "Security",
      author: "redMug.co",
      date: "March 12, 2024",
      readTime: "7 min read"
    },
    {
      id: 3,
      title: "The Impact of AI on Business Operations",
      excerpt: "How artificial intelligence is revolutionizing business processes and providing innovative solutions for companies of all sizes.",
      image: "/images/blog/ai-analysis.svg",
      category: "Artificial Intelligence",
      author: "redMug.co",
      date: "March 8, 2024",
      readTime: "6 min read"
    },
    {
      id: 4,
      title: "Building Intelligent Systems with Local LLMs: A Practical Guide",
      excerpt: "Learn how to implement AI-powered systems using local Large Language Models for secure and efficient technology solutions.",
      image: "/images/blog/llm-rag.svg",
      category: "Innovation",
      author: "redMug.co",
      date: "March 18, 2024",
      readTime: "8 min read"
    },
    {
      id: 5,
      title: "Optimizing Cloud Infrastructure for Scale",
      excerpt: "Discover strategies for scaling your cloud infrastructure efficiently while maintaining performance and reliability in high-demand environments.",
      image: "/images/blog/bi-dashboard.svg",
      category: "Cloud Technology",
      author: "redMug.co",
      date: "March 20, 2024",
      readTime: "7 min read"
    },
    {
      id: 6,
      title: "AI Implementation Best Practices",
      excerpt: "Learn essential practices for implementing AI in business operations, from selecting the right solutions to measuring ROI.",
      image: "/images/blog/ai-analysis.svg",
      category: "Artificial Intelligence",
      author: "redMug.co",
      date: "March 22, 2024",
      readTime: "6 min read"
    },
    {
      id: 7,
      title: "IT Governance in the Digital Age",
      excerpt: "Explore comprehensive frameworks for technology governance that address the challenges of digital transformation and risk management.",
      image: "/images/blog/security.svg",
      category: "IT Governance",
      author: "redMug.co",
      date: "March 25, 2024",
      readTime: "8 min read"
    }
  ];

  // Categories for filtering
  const categories = ["All", "Digital Solutions", "Security", "Artificial Intelligence", "Innovation", "Cloud Technology", "IT Governance"];
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  // Filter posts based on category and search term
  const filteredPosts = blogPosts.filter(post => {
    const matchesCategory = selectedCategory === "All" || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="page-container">
      <div className="max-w-6xl mx-auto">
        <div className="content-section">
          {/* Header Section */}
          <div className="mb-8">
            <h1 className="heading-1">Blog</h1>
            <p className="text-gray-600 text-lg">
              Insights and updates from the redMug team on digital transformation, 
              technology trends, and innovative solutions.
            </p>
          </div>

          {/* Search and Filter Section */}
          <div className="flex flex-col md:flex-row gap-4 mb-8">
            {/* Search Bar */}
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search articles..."
                className="form-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            {/* Category Filter */}
            <div className="w-full md:w-64">
              <select
                className="form-input"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Featured Post (Latest) */}
          {filteredPosts.length > 0 && (
            <div className="mb-12">
              <article className="card hover:shadow-xl transition-shadow">
                <div className="md:flex">
                  <div className="md:w-1/2">
                    <img 
                      src={filteredPosts[0].image}
                      alt={filteredPosts[0].title}
                      className="w-full h-64 md:h-full object-contain bg-[var(--neutral-light)] p-4"
                    />
                  </div>
                  <div className="p-6 md:w-1/2">
                    <div className="flex items-center mb-4">
                      <span className="inline-block bg-[var(--accent-color)] text-white text-sm px-3 py-1 rounded-full">
                        {filteredPosts[0].category}
                      </span>
                    </div>
                    <h2 className="heading-2">{filteredPosts[0].title}</h2>
                    <p className="text-gray-600 mb-4">{filteredPosts[0].excerpt}</p>
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center">
                        <span className="text-sm text-gray-500">{filteredPosts[0].author}</span>
                        <span className="mx-2 text-gray-300">•</span>
                        <span className="text-sm text-gray-500">{filteredPosts[0].readTime}</span>
                      </div>
                      <span className="text-sm text-gray-500">{filteredPosts[0].date}</span>
                    </div>
                    <Link 
                      to={`/blog/${filteredPosts[0].id}`}
                      className="btn-primary inline-block"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          )}

          {/* Blog Posts Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredPosts.slice(1).map(post => (
              <article key={post.id} className="card">
                <img 
                  src={post.image}
                  alt={post.title}
                  className="w-full h-48 object-contain bg-[var(--neutral-light)] p-4"
                />
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <span className="inline-block bg-[var(--accent-color)] text-white text-sm px-3 py-1 rounded-full">
                      {post.category}
                    </span>
                  </div>
                  <h2 className="heading-2 line-clamp-2">{post.title}</h2>
                  <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
                  <div className="flex items-center justify-between mb-4">
                    <span className="text-sm text-gray-500">{post.author}</span>
                    <span className="text-sm text-gray-500">{post.readTime}</span>
                  </div>
                  <Link 
                    to={`/blog/${post.id}`}
                    className="btn-primary inline-block"
                  >
                    Read More
                  </Link>
                </div>
              </article>
            ))}
          </div>

          {/* No Results Message */}
          {filteredPosts.length === 0 && (
            <div className="text-center py-12">
              <h2 className="heading-2">No posts found</h2>
              <p className="text-gray-600">
                Try adjusting your search or filter to find what you're looking for.
              </p>
            </div>
          )}

          {/* Newsletter Signup */}
          <div className="mt-12 bg-[var(--neutral-light)] rounded-lg p-8 text-center">
            <h2 className="heading-2">Stay Updated</h2>
            <p className="text-gray-600 mb-6">
              Subscribe to our newsletter for the latest insights and updates.
            </p>
            <form className="max-w-md mx-auto flex gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="form-input flex-1"
              />
              <button type="submit" className="btn-primary whitespace-nowrap">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
